import React from 'react';
import Intro from 'src/components/_shared/intro/intro';
import SEO from 'src/components/_shared/seo';

const Index = () => (
  <>
    <SEO title="Die Schlacht bei Warschau 1920" lang="de" description="Die Geschichte der wichtigsten Schlacht, die Sie vermutlich noch nicht gehört haben." />
    <Intro locale="de" />
  </>
);
export default Index;
